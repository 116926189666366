import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { JobTypeService } from '../../services/job-type.service';
import { SearchMarketService } from '../../services/search-market.service';
import { DropDown, SearchMarket } from '../../../modules/client/dashboard/settings/settings.model';
import { FloatLabelModule } from 'primeng/floatlabel';
import { MultiSelectModule } from 'primeng/multiselect';
import { ReviewerService } from '../../services/reviewer.service';
import { constants } from '../../constants/constants';
import { InputNumberModule } from 'primeng/inputnumber';
import { ToastService } from '../../services/toast.service';
import { CommonModule } from '@angular/common';
@Component({
    selector: 'app-update-user-data',
    standalone: true,
    templateUrl: './update-user-data.component.html',
    styleUrl: './update-user-data.component.scss',
    imports: [CommonModule, ButtonModule, FormsModule, MultiSelectModule, DropdownModule, FloatLabelModule, InputNumberModule],
})
export class UpdateUserDataComponent implements OnInit {

  firmId!: string;
  userId!: string;
  userName!: string;
  type!: string;
  value!: string;
  dropdownData: any = [];
  jobType: DropDown[] = [];
  searchMarketValues: SearchMarket[] = [];
  userProfileEditTypes: any = constants.userProfileEditTypes;
  fieldMapping = {
    [constants.userProfileEditTypes.pwcEmail]: 'pwcEmail',
    [constants.userProfileEditTypes.primaryPayRate]: 'primaryPayRate',
    [constants.userProfileEditTypes.secondaryPayRate]: 'secondaryPayRate',
    [constants.userProfileEditTypes.ranking]: 'ranking'
  };
    
  constructor(private ref: DynamicDialogRef, 
    public config: DynamicDialogConfig, 
    private searchMarketService: SearchMarketService,
    private reviewerService: ReviewerService,
    private jobTypeService: JobTypeService,
    private toast: ToastService
  ) { }

  ngOnInit(): void {
    this.firmId = this.config.data.firmId;
    this.userId = this.config.data.userId;
    this.type = this.config.data.type;
    this.userName = this.config.data.userName;

    if(this.type === this.userProfileEditTypes.searchMarket){
      this.setSearchMarkets();
    }
    else if(this.type === this.userProfileEditTypes.jobType){
      this.setJobTypes();
    }
    else if(this.type === this.userProfileEditTypes.ranking){
      this.setRanking();
    }
    else{
      this.value = this.config.data.selectedItems[0];
    }
  }

  setSearchMarkets() {
    this.searchMarketService.getFirmSearchMarkets(this.firmId).subscribe((res) => {
      this.dropdownData = res.filter((item: any) => item.isActive);
      this.dropdownData = this.dropdownData.map((val: any) => {return {name: val.name, id: val._id}});
    });
  }

  setJobTypes() {
    this.jobTypeService.getFirmJobType(this.firmId).subscribe((res) => {
      this.dropdownData = res.filter((item: any) => !item.isArchived);
      this.dropdownData = this.dropdownData.map((val: any) => {return {name: val.name, id: val._id}});
    })
  }

  setRanking(){
    Object.values(constants.userRankings).forEach((val) => {
      this.dropdownData.push({id: val.toUpperCase(), name: val})
    });
  }

  onChange($event: any): void {
    console.log(this.config.data.selectedItems);
  }

  closeDialog(){
    this.ref.close();
  }

  confirm() {
    let body = {
      userId: this.userId,
      profile: {}
    };
    let selectedIds = this.config.data.selectedItems.map((item: any) => item.id);
    if(this.type === this.userProfileEditTypes.searchMarket){
      body.profile = {
        searchMarkets: selectedIds
      }
      this.reviewerService.updateSearchMarkets(this.firmId, body).subscribe((res) => {
        this.ref.close(true);
      });
    }
    else if(this.type === this.userProfileEditTypes.jobType){
      body.profile = {
        jobCategories: selectedIds
      }
      this.reviewerService.updateJobTypes(this.firmId, body).subscribe((res) => {
        this.ref.close(true);
      });
    }
    else if(this.type === this.userProfileEditTypes.pwcEmail || this.type === this.userProfileEditTypes.primaryPayRate || this.type === this.userProfileEditTypes.secondaryPayRate){
      if(this.type === this.userProfileEditTypes.pwcEmail && !this.value.match(constants.emailRegex)){
        this.toast.showError('Invalid email');
        return;
      }
      if(!this.value){
        this.toast.showError(this.type+' should be not be empty');
        return;
      }
      const body = {
        userId: this.userId,
        field: this.fieldMapping[this.type],
        value: this.value
      };
      this.reviewerService.updateTenantFields(this.firmId, body).subscribe((res) => {
        this.ref.close(true);
      });
    }
    else if(this.type === this.userProfileEditTypes.ranking){
      const body = {
        userId: this.userId,
        field: this.fieldMapping[this.type],
        value: selectedIds[0]
      };
      this.reviewerService.updateTenantFields(this.firmId, body).subscribe((res) => {
        this.ref.close(true);
      });
    }
  }
}
