import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import {
  FileUploadModule,
} from 'primeng/fileupload';
import { ChipModule } from 'primeng/chip';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { constants } from '../../../shared/constants/constants';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { Reviewer } from '../../../store/reviewer/reviewer.interface';
import { DynamicCredentialService } from '../../../shared/services/dynamic-credential.service';
import { ReviewerService } from '../../../shared/services/reviewer.service';
import { DocumentService } from '../../../shared/services/document-service';
import { ToastService } from '../../../shared/services/toast.service';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../shared/services/storage.service';
import { EmittersService } from '../../../shared/services/emitters.service';
import { TooltipModule } from 'primeng/tooltip';
import { Id, ReviewerCredential } from '../../../modules/reviewer/profile/credentials/credentials.model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-add-credentials',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    FloatLabelModule,
    CalendarModule,
    InputTextModule,
    DropdownModule,
    MultiSelectModule,
    TagModule,
    FileUploadModule,
    ChipModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    TooltipModule
  ],
  templateUrl: './add-reviewer-credentials.component.html',
  styleUrl: './add-reviewer-credentials.component.scss'
})
export class AddReviewerCredentialsComponent {
  credentialForm!: FormGroup;
  userFirmId: string = '';
  dynamicCredentials!: Id[];
  reviewerId!: string;
  reviewerCredentials!: ReviewerCredential[];
  secondaryOptions!: string[];
  dropDownList: ReviewerCredential[] = [{}];
  maxIssuedAt!: Date;
  minExpiresAt!: Date;
  secondaryOptionsDropdown: string[] = [''];
  file!: File | null;
  uploadedDocumentId: string = '';
  firmName!: string;
  credName!: string;
  isYearlyRenewal: boolean = false;
  formValid = true;
  isFormSubmitWithoutFile = false;

  constructor(
    private fb: FormBuilder,
    private dynamicCredentialService: DynamicCredentialService,
    private reviewerService: ReviewerService,
    private documentService: DocumentService,
    private toastService: ToastService,
    private ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.initializeForm();
    this.userFirmId = this.config.data.firmId;
    this.reviewerId = this.config.data.userId;
    await this.getDynamicCredentials();
    await this.getReviewerCredentials();
    this.setDates();
    this.secondaryOptionsDropdown.length = 0;
    this.addCredentialsDropdown();
  }

  initializeForm(): void {
    this.credentialForm = this.fb.group({
      credentials: ['', [Validators.required]],
      issuedAt: ['', Validators.required],
      expiresAt: [''],
      secondaryOptions: [''],
    });
  }

  setDates(): void {
    this.maxIssuedAt = new Date();
    this.credentialForm
      .get('issuedAt')
      ?.valueChanges.subscribe((issuedAt: Date) => {
        if (issuedAt) {
          this.minExpiresAt = new Date(issuedAt);
          this.minExpiresAt.setDate(this.minExpiresAt.getDate() + 1);
        }
      });
  }

  addCredentialsDropdown(): void {
    this.dropDownList.length = 0;
    this.dynamicCredentials.forEach((cred: Id) => {
      this.dropDownList.push(this.mapCredentials(cred));
    });
  }

  mapCredentials(cred: Id): ReviewerCredential {
    const options = JSON.parse(cred.secondaryOptions.options);
    const selectedValues = options.values.map((obj: any) => obj.val).join(',');

    const mappedCred: ReviewerCredential = {
      _id: cred._id,
      autoRenewal: cred.isYearlyRenewal || false,
      isYearlyRenewal: cred.isYearlyRenewal || false,
      isDocumentationRequired: cred.isDocumentationRequired || false,
      name: cred.name || '',
      secondaryOptions: selectedValues,
      credentialId: cred._id,
      displayName: cred.description ? `${cred.name} - ${cred.description}` : cred.name
    };
    return mappedCred;
  }

  onDropdownChange(value: ReviewerCredential): void {
    this.credentialForm.get('secondaryOptions')?.setValue([]);
    this.secondaryOptionsDropdown = value?.secondaryOptions
      ?.split(',')
      .map((value) => value.trim()) || [''];
    this.credName = value?.name || '';
    this.isYearlyRenewal = value.isYearlyRenewal || false;
  }

  async getDynamicCredentials(): Promise<void> {
    this.dynamicCredentials = await lastValueFrom(
      this.reviewerService.getFilteredCredentialBasedUserSkills(this.reviewerId)
    );
    this.firmName = this.dynamicCredentials[0]?.firmName || "";
  }

  async getReviewerCredentials(): Promise<void> {
    this.reviewerCredentials = await lastValueFrom(
      this.dynamicCredentialService.getReviewerCredentials(this.reviewerId)
    );
  }

  async uploadDocToServer(document: File | null) {
    const opts = {
      documentType: 'CREDENTIALS_CERTIFICATE',
      reviewerId: this.reviewerId,
      isAdminUploaded: false,
    };
    const res = await lastValueFrom(
      this.documentService.uploadDocument(document, opts)
    );
    this.uploadedDocumentId = res.documentIds;
  }

  onFileSelect($event: Event): void {
    const input = $event.target as HTMLInputElement;
    this.file = input.files ? input.files[0] : null;
    if(this.file){
      this.isFormSubmitWithoutFile = false;
    }
    input.value = '';
  }

  removeFile() {
    this.file = null;
  }

  updateReviewerCredentials(reviewerCreds: ReviewerCredential[]): void {
    this.dynamicCredentialService.updateReviewerDynamicCredentials({
        reviewerId: this.reviewerId,
        credentials: reviewerCreds,
      })
      .subscribe({
        next: (res) => {
          this.onDiscard(true);
        },
      });
  }

  async onSubmit(): Promise<void> {
    this.formValid = false;
    if(!this.credentialForm.valid){
      return
    }
    this.formValid = true;    

    const isDocumentationRequired = this.dynamicCredentials.find(
      (cred) => cred._id === this.credentialForm.get('credentials')?.value?._id
    )?.isDocumentationRequired;
    if (isDocumentationRequired && !this.file) {
      this.isFormSubmitWithoutFile = true;
      return;
    }
    if (this.file) {
      await this.uploadDocToServer(this.file);
    }
    if (this.credentialForm.invalid) {
      this.toastService.showError('Please fill all the required fields');
      return;
    }
    const secondaryOptions = this.credentialForm.get('secondaryOptions')?.value;
    const payload: ReviewerCredential = {
      ...this.credentialForm.get('credentials')?.value,
      expiresAt: this.credentialForm.get('expiresAt')?.value,
      issuedAt: this.credentialForm.get('issuedAt')?.value,
      secondaryOptions: secondaryOptions && secondaryOptions.length ? secondaryOptions.join(',') : '',
      certificate: this.uploadedDocumentId || null,
      createdBy: constants.userRoles.firmManager
    };
    this.reviewerCredentials.push(payload);
    this.isFormSubmitWithoutFile = false;
    this.updateReviewerCredentials(this.reviewerCredentials);
  }

  onDiscard(param: boolean) {
    this.credentialForm.reset();
    this.removeFile();
    this.ref.close(param); 
  }
}
