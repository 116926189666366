import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChipModule } from 'primeng/chip';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { ReviewerService } from '../../services/reviewer.service';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from '../../services/toast.service';
import { constants } from '../../../shared/constants/constants';
import { SkillsCriteria, SelectedCriteries, ReviewerCriteriaObject } from '../../../modules/reviewer/profile/profile.interface';
import { HeadingComponent } from '../heading/heading.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-skills',
  standalone: true,
  imports: [CommonModule, ButtonModule, FormsModule, FloatLabelModule, SkeletonModule, CalendarModule, InputTextModule, DropdownModule, HeadingComponent, MultiSelectModule, AccordionModule, TagModule, FileUploadModule, ChipModule],
  templateUrl: './add-skills.component.html',
  styleUrl: './add-skills.component.scss'
})
export class AddSkillsComponent implements OnInit {

  reviewerId!: string;
  userFirmId: string = '';
  isDataReady: boolean = false;
  skillsCriteriaListing: SkillsCriteria = [];
  preSelectedOptions: any = [];

  constructor(private reviewerService: ReviewerService, 
    private ref: DynamicDialogRef, 
    public config: DynamicDialogConfig
  ) { }

  ngOnInit() {
    this.userFirmId = this.config.data.firmId;
    this.reviewerId = this.config.data.userId;
    this.getUserSkillsByDynamicCriteria();
  }

  getUserSkillsByDynamicCriteria() {
    this.reviewerService.getFilteredCriteriaBasedUserSkills(this.reviewerId).subscribe((resp: any) => {
      this.reviewerService.getReviewerCriteria(this.reviewerId).subscribe((data: SelectedCriteries) => {
        let reviewerCriteriaObject: ReviewerCriteriaObject = {};
        if (data.length > 0) {
          reviewerCriteriaObject = data.reduce((obj, item) => {
            let wrap = {
              [item.criteriaName]: item
            };
            Object.assign(obj, wrap);
            return obj;
          }, {});
        }
        resp.map((d: any) => {
          const name = d.name;
          d.criteriaSelectedOption = reviewerCriteriaObject[name.toString()] ? reviewerCriteriaObject[name.toString()].criteriaSelectedOption : [];
          d.criteriaSelectedOption = this.getCriteriaOptions(d);
          this.preSelectedOptions.push(...d.criteriaSelectedOption);
        })
        this.skillsCriteriaListing = resp;
        this.isDataReady = true;
      })
    })
  }

  getCriteriaOptions(d: any){
    const options = d.criteriaSelectedOption.map((option: any) => {
      // Find the reference from label.values
      const filterData = d.label.values.find((value: any) => value.val === option.val);
      
      // Find the reference from experienceLevels if experience is enabled
      const experienceLevel = option.isExperienceEnabled 
        ? d.experienceLevels.find((exp: any) => exp.experience === option.selectedExperienceLevel?.experience) 
        : null;
    
      // If a match is found, return the original reference
      return filterData 
        ? Object.assign(filterData, { selectedExperienceLevel: experienceLevel || null }) 
        : null;
    }).filter((item: any) => item !== null);
    return options;
  }

  onRemoveChip(data: any, index: number) {
    const criteriaSelectedOption = this.skillsCriteriaListing[index].criteriaSelectedOption;
    const option = criteriaSelectedOption.filter((d: any) => {
      return d.val !== data.val;
    })
    this.skillsCriteriaListing[index].criteriaSelectedOption = option;
  }
  
  save(){
    let criterias:any = {};
    this.skillsCriteriaListing.map((d:any) => {
      if(!criterias[d.name]){
        criterias[d.name] = {
          selectedOptions: d.criteriaSelectedOption, 
          jobCategory: d.jobCategory.map((category: any) => category._id), 
          criteriaId: d._id
        };
      }
      if(d.criteriaSelectedOption.length){
        d.criteriaSelectedOption.forEach((item: any) => {
            if (item.isExperienceEnabled) {
                // Ensure selectedExperienceLevel exists
              if (!item.selectedExperienceLevel) {
                  item.selectedExperienceLevel = { experience: "" , priority: "0" };
              } else if (!item.selectedExperienceLevel.priority) {
                  item.selectedExperienceLevel.priority = "0";
                  item.selectedExperienceLevel.experience = "";
              }
            }
            let index = this.preSelectedOptions.findIndex((option: any) => option.val == item.val);
            if(index == -1){
              item.createdBy = constants.userRoles.firmManager;
            }
        });
      }
    })

    const payload = {
      criterias: criterias,
      reviewerId: this.reviewerId
    }
    this.reviewerService.saveCriteriaReviewer(payload).subscribe((d:any)=>{
      this.onDiscard(true);
    });
  }

  onDiscard(param: boolean) {
    this.ref.close(param); 
  }
}
