<div>
  <form [formGroup]="credentialForm">
    <div class="flex flex-column justify-content-between">
      <div class="grid w-full">
        @if( isYearlyRenewal && credName){
          <span class="custom-msg" layout="row"
            ng-if="isYearlyRenewal">
            <i style="color: red; margin-right: 10px"
              class="pi pi-exclamation-circle">
            </i>{{ firmName }} requires {{ credName }} be kept up to date. Please
            provide Issuance and Expiration dates. You will be prompted to renew
            these after one year of issuance or on the expiration date.</span>
        }
        <div class="col-12">
          <p-floatLabel>
            <p-dropdown
              formControlName="credentials"
              [options]="dropDownList"
              optionLabel="displayName"
              inputId="selectCredentials"
              appendTo="body"
              (onChange)="onDropdownChange($event.value)"
            />
            <label for="selectCredentials">Select Credentials<span class="required">*</span></label>
          </p-floatLabel>
        </div>
        <div class="lg:col-6 col-12">
          <p-floatLabel>
            <p-calendar
              [maxDate]="maxIssuedAt"
              appendTo="body"
              dateFormat="mm.dd.yy"
              [showIcon]="true"
              inputId="issueDate"
              placeholder="Issue Date"
              formControlName="issuedAt"
            />
            <label for="issueDate">Issue Date<span class="required">*</span></label>
          </p-floatLabel>
          @if(credentialForm.controls['issuedAt'].dirty || credentialForm.controls['issuedAt'].touched || !formValid){
            @if(credentialForm.controls['issuedAt'].errors?.['required']){
                <div class="p-error ">This is required.</div>
            }
        }
        </div>
        <div class="lg:col-6 col-12">
          <p-floatLabel>
            <p-calendar
              [minDate]="minExpiresAt"
              appendTo="body"
              dateFormat="mm.dd.yy"
              [showIcon]="true"
              formControlName="expiresAt"
              inputId="expirationDate"
              placeholder="Expiration Date"
            />
            <label for="expirationDate">Expiration Date</label>
          </p-floatLabel>
        </div>
        <div class="col-12">
          <p-floatLabel>
            <p-multiSelect
              formControlName="secondaryOptions"
              [options]="secondaryOptionsDropdown"
              inputId="secondaryOptions"
              appendTo="body"
              placeholder="Secondary Options"
            />
            <label for="secondaryOptions">Secondary Options</label>
          </p-floatLabel>
        </div>
        <div class="col-12">
          <input
            type="file"
            #fileUpload
            style="display: none"
            (change)="onFileSelect($event)"
          />
          <div class="flex align-items-center mt-4 mb-5">

            <p-button
              (click)="fileUpload.click()"
              label="Upload certificate"
              rounded="true"
              size="large"
              icon="pi pi-plus"
            ></p-button>
            @if(isFormSubmitWithoutFile && !file){
                <span pTooltip="Documentation is Required" tooltipPosition="top" class="material-symbols-outlined incomplete-icon red">error</span>
            }
          </div>
          @if(file){
            <div class="flex align-items-center mt-4 mb-5">
              <span>{{ file.name }}
              </span>
              <p-button pTooltip="Remove" tooltipPosition="top"  [text]="true" styleClass="red" class="ml-4" size="small" rounded="true" icon="pi pi-times" (click)="removeFile()"></p-button>

            </div>
          }
          
        </div>
      </div>
      <div class="flex gap-3">
        <p-button
          label="Cancel"
          rounded="true"
          outlined="true"
          size="large"
          styleClass="white-space-nowrap"
          (onClick)="onDiscard(false)"></p-button>
        <p-button
          label="Save"
          rounded="true"
          size="large"
          styleClass="white-space-nowrap freshgreen-background"
          (onClick)="onSubmit()"
></p-button>
      </div>
    </div>
  </form>
</div>
