<div header="Update User Data" class="align-items-center">
    <div class="col-12 mb-3 p-0">
        @if (type === userProfileEditTypes.searchMarket || type === userProfileEditTypes.jobType){
            <div class="w-full flex flex-column gap-3 pb-3">
                <div class="flex gap-1">
                    Any changes will be visible to {{userName}}
                </div>
            </div>
            <p-multiSelect [(ngModel)]="config.data.selectedItems"
                display="chip" (onChange)="onChange($event)"
                [options]="dropdownData" optionLabel="name" appendTo="body"/>
        }
        @else if (type === userProfileEditTypes.pwcEmail) {
            <div class="w-full flex flex-column gap-3 pb-3">
                <div class="flex gap-1">
                    Please Enter {{type}}:
                </div>
            </div>
            <form #emailForm="ngForm">
                <input class="p-inputtext p-component" id="username" type="text" pInputText [(ngModel)]="value" [ngModelOptions]="{standalone: true}"
                width="100%"
                #emailField="ngModel"
                pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" />
                <div *ngIf="emailField.invalid && emailField.touched">
                    @if(emailField.errors?.['pattern']){
                        <div class="p-error">Invalid Email.</div>
                    }
                </div>
            </form>
        } 
        @else if (type === userProfileEditTypes.primaryPayRate || type === userProfileEditTypes.secondaryPayRate) {
            <div class="flex gap-3">
                <div class="flex gap-1 center">
                    Please Enter {{type}}:
                </div>
                <p-inputNumber [(ngModel)]="value" [style]="{'width': '4rem'}"
                styleClass="slider-style" [showButtons]="true" buttonLayout="vertical"
                spinnerMode="vertical" inputId="vertical" [min]="1" max="100"
                decrementButtonClass="p-button-text" incrementButtonClass="p-button-text"
                incrementButtonIcon="pi pi-chevron-up" decrementButtonIcon="pi pi-chevron-down"/>
            </div>
        }
        @else if (type === userProfileEditTypes.ranking) {
            <div class="w-full flex flex-column gap-3 pb-3">
                <div class="flex gap-1">
                    Please Select {{type}}:
                </div>
            </div>
            <p-dropdown
                [(ngModel)]="config.data.selectedItems[0]"
                [options]="dropdownData"
                optionLabel="name"
                appendTo="body"
                (onChange)="onChange($event)"
                inputId="float-label"
            />
        }
    </div>
   
    <div class="flex justify-content-end gap-2">
        <p-button label="Cancel" severity="secondary" (click)="closeDialog()" />
        <p-button [disabled]="!config.data.selectedItems.length" label="OK" (click)="confirm()" />
    </div>
</div>